// paper & background
$paper: #ffffff;
$default: #f6f6f6;
$default500: #eaeaea;

// // primary
// $primaryLight: #e3f2fd;
// $primaryMain: #2196f3;
// $primaryDark: #1e88e5;
// $primary200: #90caf9;
// $primary800: #1565c0;

// // secondary
// $secondaryLight: #ede7f6;
// $secondaryMain: #673ab7;
// $secondaryDark: #5e35b1;
// $secondary200: #b39ddb;
// $secondary800: #4527a0;

// primary
$primaryLight: #d3d3d3;
$primaryMain: #eaeaea;
$primaryDark: #aeaeae;
$primary200: #eeeeee;
$primary800: #929292;

// secondary
$secondaryLight: #eaeaea;
$secondaryMain: #aeaeae;
$secondaryDark: #7e7e7e;
$secondary200: #aeaeae;
$secondary800: #7e7e7e;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #4caf50;
$successDark: #27ca40;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// red
$redLight: #ffc4be;
$redMain: #ff6057;
$redDark: #a82700;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// blue
$blueLight: #d1eeff;
$blueMain: #00a0ff;
$blueDark: #003864;

// dark
$darkLight: #f9f9f9;
$darkMain: #444444;
$darkDark: #000b1c;
$dark100: #d5dfe5;
$dark200: #b2b2b2;

// black
$blackLight: #4c4c4c;
$blackMain: #2f2f2f;
$blackDark: #262626;
$black100: #9e9e9e;
$black200: #626262;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| LOGIN THEME VARIANTS ||============================== //

// login
$principalBody: #ffffff;
$principalFooter: #18385f;
$principalDark: #aeaeae;
$principal200: #ffffff;
$principal800: #929292;

// white
$whiteMain: #ffffff;

// ==============================|| Modal THEME VARIANTS ||=============================== //

// Botones
$btnModalWhiteMain: #ffffff;
$btnModalBlackMain: #000000;
$btnModalGreenMain: #42c664;
$btnModalRedMain: #ff6057;
$btnModalblueMain: #00a0ff;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;
    default: $default;
    default500: $default500;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // red
    redLight: $redLight;
    redMain: $redMain;
    redDark: $redDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // blue
    blueLight: $blueLight;
    blueMain: $blueMain;
    blueDark: $blueDark;

    // dark
    darkLight: $darkLight;
    darkMain: $darkMain;
    darkDark: $darkDark;
    dark100: $dark100;
    dark200: $dark200;

    // black
    blackLight: $blackLight;
    blackMain: $blackMain;
    blackDark: $blackDark;
    black100: $black100;
    black200: $black200;

    // white
    whiteMain: $whiteMain;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;

    // ==============================|| LOGIN THEME VARIANTS ||============================== //

    // principal
    principalBody: $principalBody;
    principalFooter: $principalFooter;

    // =================================|| Botones Modal ||================================== //

    // Botones Modal
    btnWhite: $btnModalWhiteMain;
    btnBlack: $btnModalBlackMain;
    btnGreen: $btnModalGreenMain;
    btnRed: $btnModalRedMain;
    btnblue: $btnModalblueMain;
}
